import Footer from './components/Footer';
import Header from './components/Header';
import Home from './components/Home';
import{Routes,Route} from 'react-router-dom'
import About from './components/About';
import ContactUs from './components/ContactUs';
import Products from './components/Products';
import Thankyou from './components/Thankyou';
import Gallary from './components/Gallary';
import NoMatch from './components/NoMatch';
import React, { useEffect } from 'react';
import { initGA } from './utils/analytics';
import ComplainRegister from './components/ComplainRegister';
import SolarPlant from './components/SolarPlant';
import Admin from './components/Admin';



  


function App() {
  useEffect(() => {
    // Inject Google Analytics Script
    const script = document.createElement('script');
    script.src = `https://www.googletagmanager.com/gtag/js?id=G-QDVDYQPNHM`;
    script.async = true;
    document.head.appendChild(script);

    // Initialize Google Analytics
    initGA();
  }, []);
  return (
    <>
      <Header/>
      <Routes>
       <Route path="*" element={<NoMatch/>} /> 
        <Route path="/" element={<Home />}/>
        <Route path="about" element={<About />} />
        <Route path="contactUs" element={<ContactUs />} />
        <Route path="complain-register" element={<ComplainRegister/>} />
         <Route path="products" element={<Products />} />
        <Route path="gallary" element={<Gallary />} />
        <Route path="thank-you" element={<Thankyou />} />
        <Route path='solarplant' element={<SolarPlant/>}/>
        <Route path='admin' element={<Admin/>}/>
        
        
       
    </Routes>
    
     <Footer/>
    </>
  );
}

export default App;