import React from "react";
import Carousel from "react-bootstrap/Carousel";

export default function Slider() {
  return (
    <>
      {/* slider */}
      <div className="ds">
        <Carousel fade>
          <Carousel.Item>
            <img
              className="d-block w-100"
              src="assets/images/slider/solar/solar1.webp"
              alt="First slide"
            />
            <Carousel.Caption>
              <h3 style={{ color: "#013C78", backgroundColor:"#fff" }}>Solar Mega Plant</h3>
              {/* <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p> */}
            </Carousel.Caption>
          </Carousel.Item>

          <Carousel.Item>
            <img
              className="d-block w-100"
              src="assets/images/slider/slider2.webp"
              alt="Third slide"
            />
            <Carousel.Caption>
              <h3 style={{ color: "#013C78", backgroundColor:"#fff" }}>Roof Top Solar</h3>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100"
              src="assets/images/slider/solar/solar2.webp"
              alt="Third slide"
            />
            <Carousel.Caption>
              <h3 style={{ color: "#013C78", backgroundColor:"#fff" }}>Roof Top Solar</h3>
            </Carousel.Caption>
          </Carousel.Item>
        </Carousel>
      </div>
      {/* slider */}

      {/* mobile slider */}
      <div className="ms">
        <Carousel fade>
          <Carousel.Item>
            <img
              className="d-block w-100"
              src="assets/images/slider/solar/solarmobile1.gif"
              alt="First slide"
            />
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100"
              src="assets/images/slider/solar/solar3.webp"
              alt="Second slide"
            />
            <Carousel.Caption>
              <h3 style={{ color: "#fff" }}>Solar Panel</h3>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100"
              src="assets/images/slider/solar/Inverters1.gif"
              alt="Third slide"
            />
          </Carousel.Item>
        </Carousel>
      </div>
      {/*mobile slider */}
    </>
  );
}
