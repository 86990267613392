import React from 'react'

const Services = () => {
  return (
   <>
    <section className="product-section">
        <div className="project-grid-page pt-50 ">
          <div className="container text-center">
            <h1 className="heading display-4">
              We Provide Many Products & Services
            </h1>
            <p className="lead">
              Explore our wide range of security, solar, and power solutions.
            </p>
          </div>
        </div>
      </section>

      <section>
      <div
          className="project-grid-page p-r z-1 pt-60 pb-60 "
          id="project-filter"
        >
          <div className="container">
            <div className="row project-row">
              <div className="col-lg-4 col-md-6 col-sm-12 project-column cat-7">
                <div className="project-item-three mb-30 wow fadeInUp">
                  <div className="img-holder cat-2">
                    <img src="assets/images/services/Roof-Top-Solar-Plant.webp" alt="" />
                    <div className="hover-portfolio">
                      <div className="hover-content ">
                        <h3 className="title">
                          <a href="/">SOLAR PLANT</a>
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
                <h6 className="mt-30 text-center">Roof Top Solar Plant</h6>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12 project-column cat-8">
                <div className="project-item-three mb-30 wow fadeInUp">
                  <div className="img-holder cat-2">
                    <img src="assets/images/services/inverters.webp" alt="" />
                    <div className="hover-portfolio">
                      <div className="hover-content">
                        <h3 className="title">
                          <a href="/">BATTERIES BACKUP</a>
                        </h3>
                      </div>
                    </div>
                  </div>
                  <h6 className="mt-30 text-center">
                    Inverters and Batteries Backup
                  </h6>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12 project-column cat-1">
                <div className="project-item-three mb-30 wow fadeInUp">
                  <div className="img-holder cat-2">
                    <img src="assets/images/services/CCTV-Surveillance-System.webp" alt="" />
                    <div className="hover-portfolio">
                      <div className="hover-content">
                        <h3 className="title">
                          <a href="/">CCTV</a>
                        </h3>
                      </div>
                    </div>
                  </div>
                  <h6 className="mt-30 text-center">
                    CCTV Surveillance System
                  </h6>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12 project-column cat-2">
                <div className="project-item-three mb-30 wow fadeInDown">
                  <div className="img-holder cat-2">
                    <img src="assets/images/services/Attendance-Machine.webp" alt="" />
                    <div className="hover-portfolio">
                      <div className="hover-content">
                        <h3 className="title">
                          <a href="/">ATTENDANCE MACHINE</a>
                        </h3>
                      </div>
                    </div>
                  </div>
                  <h6 className="mt-30 text-center">
                    Access Control & Time Attendance Machine
                  </h6>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12 project-column cat-3">
                <div className="project-item-three mb-30 wow fadeInUp">
                  <div className="img-holder cat-2">
                    <img src="assets/images/services/Video-Door-Phone.webp" alt="" />
                    <div className="hover-portfolio">
                      <div className="hover-content">
                        <h3 className="title">
                          <a href="/">VIDEO DOOR PHONE</a>
                        </h3>
                      </div>
                    </div>
                  </div>
                  <h6 className="mt-30 text-center">
                    Video Door Phone and Electronic Lock
                  </h6>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12 project-column cat-4">
                <div className="project-item-three mb-30 wow fadeInDown">
                  <div className="img-holder cat-2">
                    <img src="assets/images/services/Home-Alarm.webp" alt="" />
                    <div className="hover-portfolio">
                      <div className="hover-content">
                        <h3 className="title">
                          <a href="/">ALARM SYSTEM</a>
                        </h3>
                      </div>
                    </div>
                  </div>
                  <h6 className="mt-30 text-center">Home Alarm System</h6>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12 project-column cat-5">
                <div className="project-item-three mb-30 wow fadeInUp">
                  <div className="img-holder cat-2">
                    <img src="assets/images/services/fire-alarm.webp" alt="" />
                    <div className="hover-portfolio">
                      <div className="hover-content">
                        <h3 className="title">
                          <a href="/">FIRE ALARM</a>
                        </h3>
                      </div>
                    </div>
                  </div>
                  <h6 className="mt-30 text-center">Fire Alarm System</h6>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12 project-column cat-6">
                <div className="project-item-three mb-30 wow fadeInDown">
                  <div className="img-holder cat-2">
                    <img src="assets/images/services/intercom.webp" alt="" />
                    <div className="hover-portfolio">
                      <div className="hover-content">
                        <h3 className="title">
                          <a href="/">INTERCOM</a>
                        </h3>
                      </div>
                    </div>
                  </div>
                  <h6 className="mt-30 text-center">Intercom & EPABX</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
   </>
  )
}

export default Services