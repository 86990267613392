import React from 'react'
import { Link } from 'react-router-dom';

export default function Thankyou() {
  return (
      <>
        <section className="page-banner bg_cover p-r z-1 bgc" style={{backgroundImage: 'url(assets/images/bg/footer-bg-1.webp)' }}>
            
            <div className="container ">
                <div className="row">
                    <div className="col-lg-7 " >
                        <div className="page-title ">
                            <h1>Thankyou</h1>
                            
                        </div>
                    </div>
                </div>
            </div>
          </section>
          <section className="login-main-wrapper">
      <div className="main-container">
          <div className="login-process">
              <div className="login-main-container">
                  <div className="thankyou-wrapper">
                      <h1><img src="assets/images/thankyou/thankyou.jpg" alt="thanks" /></h1>
                        <p>for contacting us, we will get in touch with you soon... </p>
                        <Link to="/">Back to home</Link>
                        <div className="clr"></div>
                    </div>
                    <div className="clr"></div>
                </div>
            </div>
            <div className="clr"></div>
        </div>
    </section>
       
      </>
  )
}
