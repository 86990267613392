import React from "react";
import { Link } from "react-router-dom";

export default function About() {
  return (
    <>
      {/* banner */}
      <section
        className="page-banner bg_cover p-r z-1 "
        style={{ backgroundImage: "url(assets/images/bg/footer-bg-1.webp)" }}
      >
        <div className="container ">
          <div className="row">
            <div className="col-lg-7">
              <div className="page-title">
                <h1>About us</h1>
                <ul className="breadcrumbs-link">
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <a href="/">Home</a>
                  </li>
                  <li className="active">About us.</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* banner */}
      <section className="about-section py-5">
        <div className="container">
          {/* About Content Section */}
          <div className="row align-items-center mb-5">
            <div className="col-lg-6 mb-4 mb-lg-0">
              <div className="about-image-box wow fadeInLeft">
                <img
                  src="assets/images/about/team.webp"
                  className="img-fluid rounded shadow-lg"
                  alt="Team"
                />
              </div>
            </div>

            <div className="col-lg-6">
              <div className="about-content-box pl-lg-5">
                <div className="section-title section-title-left mb-4 wow fadeInUp">
                  <h2 className="mb-3">
                    About Us <br />
                   
                    <span style={{color:"#0192d8"}}>Unisafe Digital & Solar Energy</span>
                  </h2>
                </div>
                <p className="text-muted" style={{ textAlign: "justify" }}>
                  Founded in 2015, our service-driven business is owned and led
                  by a passionate entrepreneur with a commitment to providing
                  maximum customer satisfaction. With a dedicated team of
                  service technicians, we’ve built a reputation for reliability
                  and quality. Our mission is to deliver the highest level of
                  service while meeting the evolving needs of our customers. We
                  specialize in electronic security systems, surveillance
                  solutions, rooftop solar installations, power inverters, and
                  batteries. Through innovation and a focus on customer
                  satisfaction, we’ve cultivated a loyal customer base and
                  achieved significant success. We look forward to a long-term
                  partnership and the opportunity to serve your needs.
                </p>
              </div>
            </div>
          </div>

          {/* Services Section */}
          <div className="row text-center">
            {[
              { title: "Camera", icon: "c1.webp" },
              { title: "Biometric", icon: "c2.webp" },
              { title: "VDP", icon: "c3.webp" },
              { title: "Solar", icon: "c4.webp" },
              { title: "Batteries", icon: "c5.webp" },
              { title: "Inverter", icon: "c6.webp" },
            ].map((service, index) => (
              <div key={index} className="col-lg-2 col-md-4 col-6 mb-4">
                <div className="service-card p-4 rounded-lg shadow-sm wow fadeIn">
                  <img
                    src={`assets/images/icon/${service.icon}`}
                    alt={service.title}
                    className="img-fluid mb-3"
                  />
                  <h5 className="font-weight-bold">{service.title}</h5>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Custom Styles */}
        <style jsx>{`
          .about-section {
            background-color: #fff;
          }
          .about-image-box img {
            border-radius: 10px;
            box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
          }
          .about-content-box p {
            line-height: 1.8;
          }
          .service-card {
            transition: transform 0.3s, box-shadow 0.3s;
            background-color: #fff;
            padding: 20px;
            border-radius: 10px;
            box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
          }
          .service-card:hover {
            transform: translateY(-5px);
            box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
          }
          .service-card img {
            max-width: 80px;
          }
          @media (max-width: 768px) {
            .about-content-box {
              padding-left: 0;
            }
          }
        `}</style>
      </section>
    </>
  );
}
