import React, { useState, useEffect, useCallback } from "react";

const Testimonials = () => {
  const testimonials = [
    {
      text: "The agency was great to work with—very responsive and professional. They gave helpful suggestions tailored to our needs and managed everything smoothly from start to finish. Got the job done on time and were super reliable throughout.",
      name: "Hitesh Jangid",
    },
    {
      text: "The team at Unisafe Digital provided exceptional service from start to finish. Highly recommended!",
      name: "Sarah Smith",
    },
    {
      text: "Thanks to Unisafe Digital, we are now fully reliant on renewable energy. The installation process was quick and smooth.",
      name: "David Brown",
    },
  ];

  const [current, setCurrent] = useState(0);

  // Wrap the nextTestimonial function in useCallback to memoize it
  const nextTestimonial = useCallback(() => {
    setCurrent((current + 1) % testimonials.length);
  }, [current, testimonials.length]);

  useEffect(() => {
    const interval = setInterval(() => {
      nextTestimonial();
    }, 5000); // 5 seconds interval

    // Clean up the interval on component unmount
    return () => clearInterval(interval);
  }, [nextTestimonial]);

  const prevTestimonial = () => {
    setCurrent((current - 1 + testimonials.length) % testimonials.length);
  };

  return (
    <section className="mb-5">
      <div className="container">
        <h3 className="text-center mb-5 text-uppercase" style={{fontSize:"50px",}}>Testimonials</h3>
        <div className="row text-center">
          <div className="col-lg-3"></div>
          <div className="col-lg-6">
            <div className="testimonial-container">
              <p>"{testimonials[current].text}"</p>
              <h3>- {testimonials[current].name}</h3>
            </div>

            <div className="testimonial-controls">
              <button onClick={prevTestimonial}>&#10094;</button>
              <button onClick={nextTestimonial}>&#10095;</button>
            </div>
          </div>
          <div className="col-lg-3"></div>
        </div>
      </div>
    </section>
  );
};

export default Testimonials;
